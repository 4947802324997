// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rooms-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
  padding-bottom: 2rem;
}

.room-calendar-container {
  display: flex;
  width: 100%;
}
.room-calendar {
  width: 100%;
  min-height: 60vh;
  border: 0;
  margin: 1rem;
}

.room-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 766px) {
  .room-container {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 425px) {
  .rooms-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/RoomBooking.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;AACF;AACA;EACE;IACE,qCAAqC;EACvC;AACF","sourcesContent":[".rooms-grid {\r\n  display: grid;\r\n  grid-template-columns: repeat(3, 1fr);\r\n  gap: 1rem;\r\n  width: 100%;\r\n  padding-bottom: 2rem;\r\n}\r\n\r\n.room-calendar-container {\r\n  display: flex;\r\n  width: 100%;\r\n}\r\n.room-calendar {\r\n  width: 100%;\r\n  min-height: 60vh;\r\n  border: 0;\r\n  margin: 1rem;\r\n}\r\n\r\n.room-container {\r\n  display: flex;\r\n  flex-direction: row;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n@media (max-width: 766px) {\r\n  .room-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n}\r\n@media (max-width: 425px) {\r\n  .rooms-grid {\r\n    grid-template-columns: repeat(2, 1fr);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
