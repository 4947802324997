import "./Navbar.css";
import textLogo from "../text-logo.png";
import Logo from "./Logo";
import NavbarLink from "./NavbarLink";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

const navbarLinksEng = {
  home: "~/",
  "about us": "/about-us",
  "join us": "/join-us",
  events: "~/events",
  ministries: "/ministries",
  "find us": "/find-us",
  resources: "/resources",
};

const navbarSubpagesEng = {
  home: {},
  "about us": {
    "about our church": "/about-us/about our church",
    "doctrinal beliefs": "/about-us/doctrinal beliefs",
    "church history": "/about-us/church history",
    "pastoral team and staff": "/about-us/pastoral team and staff",
    "missionaries we support": "/about-us/missionaries we support",
  },
  "join us": {
    "service times": "/join-us/service times",
    "fellowship groups": "/join-us/fellowship groups",
    "sunday school": "/join-us/sunday school",
    "koala playgroup": "/join-us/koala playgroup",
    "rewire youth group": "/join-us/rewire youth group",
  },
  events: {},
  ministries: { "community outreach": "/ministries/community outreach", training: "/ministries/training", mission: "/ministries/mission" },
  "find us": { location: "/find-us/location", "social media": "/find-us/social media", contacts: "/find-us/contacts" },
  resources: { sermons: "/resources/sermons", "room booking": "/resources/room booking" },
};

const navbarLinksTrad = {
  主頁: "~/",
  關於我們: "/about-us",
  加入我們: "/join-us",
  活動: "~/events",
  事工: "/ministries",
  尋找我們: "/find-us",
  資源: "/resources",
};

const navbarSubpagesTrad = {
  主頁: {},
  關於我們: {
    關於我們的教會: "/about-us/about our church",
    教義基礎: "/about-us/doctrinal beliefs",
    教會歷史: "/about-us/church history",
    教牧同工: "/about-us/pastoral team and staff",
    我們所支持的宣教士: "/about-us/missionaries we support",
  },
  加入我們: {
    崇拜時間: "/join-us/service times",
    團契: "/join-us/fellowship groups",
    主日學: "/join-us/sunday school",
    小樹熊遊戲組: "/join-us/koala playgroup",
    "rewire 少年團契": "/join-us/rewire youth group",
  },
  活動: {},
  事工: { 社區外展: "/ministries/community outreach", 訓練: "/ministries/training", 宣教: "/ministries/mission" },
  尋找我們: { 地點: "/find-us/location", 社群媒體: "/find-us/social media", 聯絡我們: "/find-us/contacts" },
  資源: { 證道: "/resources/sermons", 房間預訂: "/resources/room booking" },
};

const navbarLinksSimp = {
  主页: "~/",
  关于我们: "/about-us",
  加入我们: "/join-us",
  活动: "~/events",
  事工: "/ministries",
  寻找我们: "/find-us",
  资源: "/resources",
};

const navbarSubpagesSimp = {
  主页: {},
  关于我们: {
    关于我们的教会: "/about-us/about our church",
    教义基础: "/about-us/doctrinal beliefs",
    教会历史: "/about-us/church history",
    教牧同工: "/about-us/pastoral team and staff",
    我们所支持的宣教士: "/about-us/missionaries we support",
  },
  加入我们: {
    崇拜时间: "/join-us/service times",
    团契: "/join-us/fellowship groups",
    主日学: "/join-us/sunday school",
    小树熊游戏组: "/join-us/koala playgroup",
    "rewire 少年团契": "/join-us/rewire youth group",
  },
  活动: {},
  事工: { 社区外展: "/ministries/community outreach", 训练: "/ministries/training", 宣教: "/ministries/mission" },
  寻找我们: { 地点: "/find-us/location", 社交媒体: "/find-us/social media", 联络我们: "/find-us/contacts" },
  资源: { 证道: "/resources/sermons", 房间预订: "/resources/room booking" },
};

export default function Navbar() {
  // React.useEffect(() => {
  //   if (localStorage.getItem("i18nextLng") === null) {
  //     localStorage.setItem("i18nextLng", "en");
  //   }
  // }, []);
  // language selector

  const { t, i18n } = useTranslation();
  let navbarLinks = {};
  let navbarSubpages = {};

  let currentLanguage = window.localStorage.getItem("i18nextLng");

  if (currentLanguage.includes("trad")) {
    navbarLinks = navbarLinksTrad;
    navbarSubpages = navbarSubpagesTrad;
  } else if (currentLanguage.includes("simp")) {
    navbarLinks = navbarLinksSimp;
    navbarSubpages = navbarSubpages = navbarSubpagesSimp;
  } else {
    navbarLinks = navbarLinksEng;
    navbarSubpages = navbarSubpagesEng;
  }

  const [hoveredLink, setHoveredLink] = React.useState("");
  const [sublinks, setSublinks] = React.useState({});

  const [navbarMenuOpen, setNavbarMenuOpen] = React.useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = React.useState(false);

  React.useEffect(() => {
    try {
      const mobileNavbar = document.querySelector("#mobile-navbar");
      const container = document.querySelector(".container");
      const footer = document.querySelector("footer");
      const header = document.querySelector("header");

      if (navbarMenuOpen) {
        header.classList.add("mobile-navbar-open");
        mobileNavbar.classList.remove("mobile-navbar-closed-state");
        container.classList.add("mobile-navbar-open");
        footer.classList.add("mobile-navbar-open");
        document.body.style.overflow = "hidden";
      } else {
        header.classList.remove("mobile-navbar-open");
        mobileNavbar.classList.add("mobile-navbar-closed-state");
        container.classList.remove("mobile-navbar-open");
        footer.classList.remove("mobile-navbar-open");
        document.body.style.overflow = "auto";
      }
    } catch (e) {}
  }, [navbarMenuOpen]);

  React.useEffect(() => {
    try {
      const mobileLanguage = document.querySelector("#mobile-language");

      if (languageMenuOpen) {
        mobileLanguage.classList.remove("mobile-language-closed-state");
      } else {
        mobileLanguage.classList.add("mobile-language-closed-state");
      }
    } catch (e) {}
  }, [languageMenuOpen]);

  function NavbarLinks() {
    const map = Object.keys(navbarLinks).map((l, i) => {
      const url = navbarLinks[l].replace(" ", "-");
      const isLink = navbarLinks[l].includes("~") ? true : false;

      if (l.replace(" ", "-") === hoveredLink) {
        if (isLink) {
          return (
            <Link
              to={url.replace("~", "")}
              className="link-selected link"
              style={{ textTransform: "uppercase", fontSize: localStorage.getItem("i18nextLng") != "en" ? "1.5rem" : undefined }}
              onMouseEnter={() => {
                onLinkHover(l);
              }}
            >
              <span>{l}</span>
            </Link>
          );
        } else {
          return (
            <Link
              className="link-selected link"
              style={{ textTransform: "uppercase", fontSize: localStorage.getItem("i18nextLng") != "en" ? "1.5rem" : undefined }}
              onMouseEnter={() => {
                onLinkHover(l);
              }}
            >
              <span>{l}</span>
            </Link>
          );
        }
      } else {
        return (
          <Link
            to={url.replace("~", "")}
            className="link"
            style={{ textTransform: "uppercase", fontSize: localStorage.getItem("i18nextLng") != "en" ? "1.5rem" : undefined }}
            onMouseEnter={() => {
              onLinkHover(l);
            }}
          >
            <span>{l}</span>
          </Link>
        );
      }
    });

    return map;
  }

  function MembersLinks() {
    return (
      <Link
        to={"/members"}
        id="members-link"
        className="link"
        style={{ height: "2rem", fontSize: localStorage.getItem("i18nextLng") != "en" ? "1.5rem" : undefined }}
        onMouseEnter={() => {
          // onLinkHover("members");
        }}
      >
        <span>{t("navbar.h")}</span>
      </Link>
    );
  }

  function Sublinks() {
    const map = Object.keys(sublinks).map((l) => {
      let url = "";
      const map2 = Object.keys(navbarSubpages).map((s) => {
        if (Object.keys(navbarSubpages[s]).includes(l)) {
          url = navbarSubpages[s][l].replace(/\s+/g, "-");
        }
      });

      return (
        <>
          {/* <a href={hoveredLink + "/" + url} class="link" style={{ textTransform: "uppercase" }}>
            <span>{l}</span>
          </a> */}
          <Link to={url} className="link" style={{ textTransform: "uppercase", fontSize: localStorage.getItem("i18nextLng") != "en" ? "1.5rem" : undefined }}>
            <span>{l}</span>
          </Link>
        </>
      );
    });

    return map;
  }

  function LanguageSelector() {
    return (
      <>
        <a
          onClick={() => {
            changeLanguage("en");
          }}
          class="language"
          style={window.localStorage.getItem("i18nextLng").includes("en") ? { color: "var(--primary-color)" } : {}}
        >
          en
        </a>
        <div style={{ height: "40%", width: "1px", backgroundColor: "white", margin: "0rem 1rem" }}></div>
        <a
          onClick={() => {
            changeLanguage("simp");
          }}
          class="language"
          style={window.localStorage.getItem("i18nextLng").includes("simp") ? { color: "var(--primary-color)" } : {}}
        >
          中文(簡)
        </a>
        <div style={{ height: "40%", width: "1px", backgroundColor: "white", margin: "0rem 1rem" }}></div>
        <a
          onClick={() => {
            changeLanguage("trad");
          }}
          class="language"
          style={window.localStorage.getItem("i18nextLng").includes("trad") ? { color: "var(--primary-color)" } : {}}
        >
          中文(繁)
        </a>
      </>
    );
  }

  function MobileLanguage() {
    return (
      <>
        <div id="mobile-language" className="mobile-language-closed-state">
          <a
            onClick={() => {
              changeLanguage("en");
              setLanguageMenuOpen(false);
            }}
            class="language"
            style={window.localStorage.getItem("i18nextLng").includes("en") ? { color: "black", backgroundColor: "var(--primary-color)" } : {}}
          >
            EN
          </a>
          <a
            onClick={() => {
              changeLanguage("simp");
              setLanguageMenuOpen(false);
            }}
            class="language"
            style={window.localStorage.getItem("i18nextLng").includes("simp") ? { color: "black", backgroundColor: "var(--primary-color)" } : {}}
          >
            中文(簡)
          </a>
          <a
            onClick={() => {
              changeLanguage("trad");
              setLanguageMenuOpen(false);
            }}
            class="language"
            style={window.localStorage.getItem("i18nextLng").includes("trad") ? { color: "black", backgroundColor: "var(--primary-color)" } : {}}
          >
            中文(繁)
          </a>
        </div>
      </>
    );
  }

  function onLinkHover(l) {
    setHoveredLink(l.replace(" ", "-"));
    setSublinks(navbarSubpages[l]);
    const sublinks = document.getElementById("sublinks");
  }

  function changeLanguage(language) {
    i18n.changeLanguage(language);
    setHoveredLink("");
    setSublinks("");
  }

  function MobileSublinks(link, sublinks, i) {
    const map = Object.keys(sublinks).map((l) => {
      let url = "";
      const map2 = Object.keys(navbarSubpages).map((s) => {
        if (Object.keys(navbarSubpages[s]).includes(l)) {
          url = navbarSubpages[s][l].replace(/\s+/g, "-");
        }
      });

      return (
        <>
          {/* <a href={hoveredLink + "/" + url} class="link" style={{ textTransform: "uppercase" }}>
            <span>{l}</span>
          </a> */}
          <Link
            to={url}
            className="mobile-sublink"
            style={{ color: "white", textTransform: "uppercase", fontSize: localStorage.getItem("i18nextLng") != "en" ? "1.5rem" : undefined }}
            // onMouseEnter={() => {
            //   onSublinkHover();
            // }}
            onClick={() => {
              setNavbarMenuOpen(false);
            }}
          >
            <span>{l}</span>
            <span class="material-symbols-outlined" style={{ fontSize: "1rem" }}>
              chevron_right
            </span>
          </Link>
        </>
      );
    });

    return (
      <div className="mobile-sublink-group" id={i}>
        {map}
      </div>
    );
  }

  function MobileNavbar() {
    const map = Object.keys(navbarLinks).map((l, i) => {
      const url = navbarLinks[l].replace(" ", "-");
      const isLink = navbarLinks[l].includes("~") ? true : false;

      if (isLink) {
        return (
          <Link
            to={url.replace("~", "")}
            // className="link-selected link"
            style={{ textTransform: "uppercase", fontSize: localStorage.getItem("i18nextLng") != "en" ? "1.5rem" : undefined }}
            className="mobile-link"
            // onMouseEnter={() => {
            //   onLinkHover(l);
            // }}
            onClick={() => {
              const navbar = document.querySelector("header");
              navbar.classList.remove("mobile-navbar-open");
              setNavbarMenuOpen(false);
            }}
          >
            <span>{l}</span>
            <span class="material-symbols-outlined">chevron_right</span>
          </Link>
        );
      } else {
        return (
          <>
            <Link
              // className="link-selected link"
              className="mobile-link"
              onClick={() => {
                let allSublinkGroups = document.querySelectorAll(".mobile-sublink-group");

                for (let j = 0; j < allSublinkGroups.length; j++) {
                  allSublinkGroups[j].style.display = "none";
                }

                let sublinkGroup = document.getElementById(`group-${i}`);

                console.log(sublinkGroup);
                console.log(sublinkGroup.style.display);

                if (sublinkGroup.style.display == "flex") {
                  sublinkGroup.style.display = "none";
                } else {
                  sublinkGroup.style.display = "flex";
                }
              }}
              style={{ textTransform: "uppercase", fontSize: localStorage.getItem("i18nextLng") != "en" ? "1.5rem" : undefined }}
              // onMouseEnter={() => {
              //   onLinkHover(l);
              // }}
            >
              <span>{l}</span>
            </Link>

            {MobileSublinks(l.replace(" ", "-"), navbarSubpages[l], "group-" + i)}
          </>
        );
      }
    });

    return (
      <div id="mobile-navbar" className="mobile-navbar-closed-state">
        <div id="mobile-navbar-container">{map}</div>
        <div
          id="mobile-navbar-close"
          onClick={() => {
            setNavbarMenuOpen(false);
            const container = document.querySelector(".container");
            container.classList.remove("container-navbar-open");

            const navbar = document.querySelector("header");
            navbar.classList.remove("mobile-navbar-open");
          }}
        >
          <span id="mobile-navbar-close-button" class="material-symbols-outlined">
            chevron_left
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      {MobileNavbar()}
      {MobileLanguage()}
      <header>
        <div
          id="mobile-navbar-button"
          onClick={() => {
            setNavbarMenuOpen(true);
            const container = document.querySelector(".container");
            container.classList.add("container-navbar-open");

            const navbar = document.querySelector("header");
            navbar.classList.add("mobile-navbar-open");
          }}
        >
          <span class="material-symbols-outlined">menu</span>
        </div>
        <div id="header-left">
          <Logo />
        </div>
        <div
          id="mobile-language-button"
          onClick={() => {
            setLanguageMenuOpen(!languageMenuOpen);
          }}
        >
          <span class="material-symbols-outlined">language</span>
        </div>
        <div id="header-right">
          <div id="language-selector">{LanguageSelector()}</div>
          <div id="navbar">
            {NavbarLinks()}
            {MembersLinks()}
          </div>
        </div>
      </header>
      <div id="sublinks" style={{ backgroundColor: "var(--primary-color)" }}>
        {Sublinks()}
      </div>
    </>
  );
}
