// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aoc-values-container {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.aoc-value {
  background-color: var(--primary-color);
  flex: 1 1;
  text-align: center;
  font-family: "WMFD-Bold";
  border-radius: 2rem;
  padding: 1rem;
  color: white;
}

@media (max-width: 768px) {
  .aoc-values-container {
    flex-direction: column;
  }

  .aoc-value {
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/AboutOurChurch.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,WAAW;EACX,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,sCAAsC;EACtC,SAAO;EACP,kBAAkB;EAClB,wBAAwB;EACxB,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".aoc-values-container {\r\n  display: flex;\r\n  width: 100%;\r\n  gap: 0.5rem;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.aoc-value {\r\n  background-color: var(--primary-color);\r\n  flex: 1;\r\n  text-align: center;\r\n  font-family: \"WMFD-Bold\";\r\n  border-radius: 2rem;\r\n  padding: 1rem;\r\n  color: white;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .aoc-values-container {\r\n    flex-direction: column;\r\n  }\r\n\r\n  .aoc-value {\r\n    width: 80%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
